var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'List Student Profile',
          src: '#student',
          path: '/dashboard/admin/list-students',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'List Trainer',
          src: '#trainer',
          path: '/dashboard/admin/list-trainers',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'List Courses',
          src: '#course',
          path: '/dashboard/admin/list-courses',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'List Practice Hololens',
          src: '#hololens',
          path: '/dashboard/admin/list-practice',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'List Invoice Withdrawal',
          src: '#invoice',
          path: '/dashboard/admin/list-invoice',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'List FAQ',
          src: '#faq',
          path: '/dashboard/admin/faq',
        }}}),_c('div',{staticClass:"dropdown-divider"}),_c('sidebar-item',{attrs:{"link":{
          name: 'Setting',
          src: '#cog',
          path: '/dashboard/admin/setting',
        }}})],1),_c('template',{slot:"links-after"})],2),_c('div',{staticClass:"main-content"},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }