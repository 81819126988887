// import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AuthLayout from "@/Layout/AuthLayout.vue";
import DashboardLayout from "@/Layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/views/NotFoundPage.vue";
// import Login from "@/views/Login.vue";
import store from "@/store";
import { parseJwt } from "@/util/parse";
import Vue from "vue";

const routes = [
  {
    path: "/",
    component: AuthLayout,
    name: "Authentication",
    redirect: "/login",
    children: [
      {
        path: "login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "Login" */ "@/views/Login.vue")
      }
    ]
  },
  {
    path: "/dashboard/admin",
    component: DashboardLayout,
    // meta: {
    //   requiresAuth: true
    // },
    beforeEnter: (to, from, next) => {
      const { ri } = parseJwt(Vue.prototype.$cookies.get("CERT"));
      if (store.state.auth.role === 1 || ri === 1) {
        next();
      }
      // debugger;
    },
    // redirect: "/dashboard/admin/list-students",
    children: [
      {
        path: "/",
        name: "Dashboard-Admin",
        component: () =>
          import(/* webpackChunkName: "Admin" */ "@/views/Dashboard/index.vue")
      },
      {
        path: "list-students",
        name: "ListStudents",
        component: () =>
          import(
            /* webpackChunkName: "List-Students" */ "@/views/Dashboard/List-Students/index.vue"
          )
      },
      {
        path: "list-students/:id",
        name: "ListStudentsId",
        component: () =>
          import(
            /* webpackChunkName: "studentID" */ "@/views/Dashboard/List-Students/Id.vue"
          )
      },
      {
        path: "list-students/:id/usage",
        name: "ListStudentsUsage",
        component: () =>
          import(/* webpackChunkName: "Admin" */ "@/views/Dashboard/List-Students/Usage.vue")
      },
      {
        path: "list-students/:id/course",
        name: "ListStudentsCourse",
        component: () =>
          import(
            /* webpackChunkName: "studentCourse" */ "@/views/Dashboard/List-Students/Course.vue"
          )
      },
      {
        path: "list-trainers",
        name: "ListTrainers",
        component: () =>
          import(
            /* webpackChunkName: "List-Trainers" */ "@/views/Dashboard/List-Trainers/index.vue"
          )
      },
      {
        path: "list-trainers/add",
        name: "addTrainer",
        component: () =>
          import(
            /* webpackChunkName: "add Trainer" */ "@/views/Dashboard/List-Trainers/add.vue"
          )
      },
      {
        path: "list-trainers/:id/edit",
        name: "editTrainer",
        component: () =>
          import(
            /* webpackChunkName: "edit Trainer" */ "@/views/Dashboard/List-Trainers/edit.vue"
          )
      },
      {
        path: "list-trainers/:id/courses",
        name: "trainerCourses",
        component: () =>
          import(
            /* webpackChunkName: "trainer course" */ "@/views/Dashboard/List-Trainers/course.vue"
          )
      },
      {
        path: "list-courses",
        name: "listCourses",

        component: () =>
          import(
            /* webpackChunkName: "list courses" */ "@/views/Dashboard/List-Courses/index.vue"
          )
      },
      {
        path: "list-courses/add",
        name: "addCourse",

        component: () =>
          import(
            /* webpackChunkName: "add course" */ "@/views/Dashboard/List-Courses/add.vue"
          )
      },
      {
        path: "list-courses/:id/edit",
        name: "editCourse",

        component: () =>
          import(
            /* webpackChunkName: "edit course" */ "@/views/Dashboard/List-Courses/edit.vue"
          )
      },
      {
        path: "list-courses/:id",
        name: "detailCourse",

        component: () =>
          import(
            /* webpackChunkName: "detail course" */ "@/views/Dashboard/List-Courses/detail.vue"
          )
      },
      {
        path: "setting",
        name: "setting",

        component: () =>
          import(
            /* webpackChunkName: "setting" */ "@/views/Dashboard/Setting/index.vue"
          )
      },
      {
        path: "faq",
        name: "faq",

        component: () =>
          import(
            /* webpackChunkName: "faq" */ "@/views/Dashboard/List-FAQ/index.vue"
          )
      },
      {
        path: "faq/add",
        name: "addFAQ",

        component: () =>
          import(
            /* webpackChunkName: "add faq" */ "@/views/Dashboard/List-FAQ/Add.vue"
          )
      },
      {
        path: "faq/:id/edit",
        name: "editFAQ",

        component: () =>
          import(
            /* webpackChunkName: "add faq" */ "@/views/Dashboard/List-FAQ/id/Edit.vue"
          )
      },
      {
        path: "list-practice",
        name: "listPractice",

        component: () =>
          import(
            /* webpackChunkName: "add faq" */ "@/views/Dashboard/List-Pratice/index.vue"
          )
      },
      {
        path: "list-practice/history",
        name: "listPracticeHistory",

        component: () =>
          import(
            /* webpackChunkName: "add faq" */ "@/views/Dashboard/List-Pratice/history.vue"
          )
      },
      {
        path: "list-invoice",
        name: "listInvoice",

        component: () =>
          import(
            /* webpackChunkName: "add faq" */ "@/views/Dashboard/List-Invoice/index.vue"
          )
      },
      {
        path: "list-invoice/:id/detail-income",
        name: "listdetailIncome",

        component: () =>
          import(
            /* webpackChunkName: "add faq" */ "@/views/Dashboard/List-Invoice/detail-income.vue"
          )
      }
    ]
  },
  { path: "*", component: NotFound }
];

export default routes;
