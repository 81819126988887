const types = {
  SET_COUNT_QNA: "SET_COUNT_QNA"
};

const course = {
  namespaced: true,
  state: () => ({
    countQNA: 0
  }),
  mutations: {
    [types.SET_COUNT_QNA](state, payload) {
      state.countQNA = payload;
    }
  },
  actions: {
    setCountQNA({ commit }, payload) {
      commit(types.SET_COUNT_QNA, payload);
    }
  },
  getters: {
    getCountQNA: state => {
      return state.countQNA;
    }
  }
};
export default course;
