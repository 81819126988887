<template>
  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="student" viewBox="0 0 10 16">
      <g>
        <path
          d="M4.88324 7.75439C2.18677 7.75439 0 9.94116 0 12.6376V14.9457C0 15.5273 0.472704 16 1.05434 16H8.71214C9.29377 16 9.76647 15.5273 9.76647 14.9457V12.6376C9.76647 9.94116 7.5797 7.75439 4.88324 7.75439Z"
          fill="currentColor"
        />
        <path
          d="M7.47075 2.81362L9.64313 1.98125L5.00858 0L0.374023 1.98125L0.717248 2.11278V4.58523C0.688474 4.59345 0.667922 4.61811 0.667922 4.64894V5.0045C0.667922 5.02094 0.674088 5.03532 0.682309 5.0456L0.616541 5.37444C0.610375 5.40527 0.632983 5.43198 0.663811 5.43198H0.869335C0.900163 5.43198 0.922771 5.40321 0.916605 5.37444L0.850838 5.04766C0.861114 5.03532 0.865224 5.02094 0.865224 5.00655V4.651C0.865224 4.62017 0.844672 4.5955 0.815899 4.58728V2.15183L2.29361 2.71702V3.17739C2.16002 3.50417 2.08603 3.85973 2.08603 4.23378C2.08603 5.77726 3.33767 7.0289 4.88115 7.0289C6.42463 7.0289 7.67627 5.77726 7.67627 4.23378C7.67627 3.85973 7.60228 3.50417 7.46869 3.17739V2.81362H7.47075Z"
          fill="currentColor"
        />
      </g>
    </symbol>
    <symbol id="hololens" viewBox="0 0 20 9">
      <path
        d="M0 3.55788C0 3.80413 0 4.05038 0 4.30088C0 4.41551 0.0551941 4.43674 0.152845 4.39853C0.39485 4.30088 0.632609 4.20323 0.874614 4.10982C0.993494 4.06312 1.0402 3.9782 1.0317 3.85508C1.01472 3.51967 0.997739 3.18426 0.980757 2.84885C0.968019 2.58986 0.951037 2.33087 0.929808 2.07188C0.921317 1.95725 0.887351 1.92329 0.7897 1.99122C0.556187 2.14831 0.322673 2.31814 0.106142 2.5007C0.042457 2.55589 0.0084914 2.67053 0.0042457 2.75969C-0.0042457 3.01868 0.0042457 3.2904 0 3.55788C0.0042457 3.55788 0 3.55788 0 3.55788Z"
        fill="currentColor"
      />
      <path
        d="M1.3381 6.11808C1.44848 6.12657 1.4315 6.0544 1.41876 5.99071C1.35932 5.68927 1.29564 5.39207 1.23195 5.09062C1.21072 4.98873 1.18525 4.88683 1.16402 4.78493C1.14279 4.67454 1.07911 4.64058 0.981457 4.67879C0.709732 4.78493 0.438008 4.89957 0.166283 5.00996C0.0601404 5.05241 0.0474033 5.13308 0.094106 5.22224C0.132317 5.29017 0.174774 5.36235 0.225723 5.42179C0.501693 5.74446 0.854086 5.95675 1.25318 6.09685C1.2829 6.10959 1.31262 6.11384 1.3381 6.11808Z"
        fill="currentColor"
      />
      <path
        d="M18.0615 4.39005C17.9809 4.36882 17.9044 4.33485 17.8238 4.31363C16.3548 3.88906 14.8518 3.63431 13.3318 3.46873C12.4402 3.37108 10.8396 3.31164 10.2112 3.28617C10.1391 3.28192 10.0711 3.28192 9.99894 3.28192C9.92677 3.28192 9.85883 3.28617 9.78666 3.28617C9.15829 3.31164 7.55766 3.37108 6.66607 3.46873C5.14611 3.63431 3.64738 3.8933 2.17412 4.31363C2.09345 4.33485 2.01703 4.36882 1.93636 4.39005C1.81323 4.42401 1.77078 4.49195 1.78776 4.61932C1.90239 5.34533 2.02127 6.07135 2.40339 6.71669C2.84069 7.45544 3.50727 7.92247 4.27998 8.24939C5.4985 8.8268 7.47275 8.76736 8.16055 8.74614C8.38982 8.73764 8.58512 8.66122 8.74222 8.49139C8.99696 8.22392 9.24745 7.95219 9.50644 7.68896C9.6423 7.55309 9.78241 7.40449 9.9947 7.40449C10.207 7.40449 10.3471 7.55309 10.483 7.68896C10.7419 7.95219 10.9924 8.22392 11.2472 8.49139C11.4043 8.65698 11.5996 8.73764 11.8288 8.74614C12.5124 8.76736 14.4909 8.8268 15.7094 8.24939C16.4864 7.92247 17.1487 7.45544 17.586 6.71669C17.9681 6.07135 18.087 5.34533 18.2016 4.61932C18.2314 4.49195 18.1847 4.41977 18.0615 4.39005Z"
        fill="currentColor"
      />
      <path
        d="M18.2516 1.4308C18.0011 1.30768 17.7506 1.17181 17.4916 1.07416C16.2774 0.624118 15.0164 0.377867 13.7342 0.233513C12.8001 0.127371 11.174 0.0467027 10.3759 0.0084914C10.2527 0.0042457 10.1254 0 10.0022 0C9.8791 0 9.75173 0.0042457 9.62861 0.0084914C8.82617 0.0467027 7.20431 0.127371 6.27026 0.233513C4.98806 0.377867 3.72709 0.624118 2.51282 1.07416C2.24958 1.17181 2.00333 1.30768 1.75284 1.4308C1.6382 1.48599 1.58301 1.58789 1.58301 1.71951C1.58301 1.90632 1.58301 2.09738 1.5915 2.28419C1.61273 2.72574 1.64669 3.16305 1.66792 3.6046C1.67641 3.72772 1.73161 3.7532 1.842 3.72772C2.35573 3.60035 2.86521 3.46449 3.37894 3.34986C4.36819 3.12908 5.37442 2.97624 6.38065 2.85736C7.58643 2.713 8.79645 2.66205 10.0065 2.6663C11.2165 2.66205 12.4265 2.713 13.6323 2.85736C14.6385 2.98048 15.6448 3.12908 16.634 3.34986C17.1477 3.46449 17.6615 3.60035 18.171 3.72772C18.2771 3.7532 18.3365 3.72772 18.345 3.6046C18.3663 3.16305 18.4002 2.72574 18.4215 2.28419C18.4299 2.09738 18.4299 1.90632 18.4299 1.71951C18.4215 1.58789 18.3663 1.48599 18.2516 1.4308Z"
        fill="currentColor"
      />
      <path
        d="M19.9975 3.55788C19.9975 3.80413 19.9975 4.05038 19.9975 4.30088C19.9975 4.41551 19.9423 4.43674 19.8447 4.39853C19.6027 4.30088 19.3649 4.20323 19.1229 4.10982C19.004 4.06312 18.9573 3.9782 18.9658 3.85508C18.9828 3.51967 18.9998 3.18426 19.0168 2.84885C19.0295 2.58986 19.0465 2.33087 19.0677 2.07188C19.0762 1.95725 19.1102 1.92329 19.2078 1.99122C19.4413 2.14831 19.6748 2.31814 19.8914 2.5007C19.9551 2.55589 19.989 2.67053 19.9933 2.75969C20.006 3.01868 19.9975 3.2904 19.9975 3.55788Z"
        fill="currentColor"
      />
      <path
        d="M18.6642 6.11808C18.5538 6.12657 18.5708 6.0544 18.5835 5.99071C18.643 5.68927 18.7066 5.39207 18.7703 5.09062C18.7916 4.98873 18.817 4.88683 18.8383 4.78493C18.8595 4.67454 18.9232 4.64058 19.0208 4.67879C19.2925 4.78493 19.5643 4.89957 19.836 5.00996C19.9421 5.05241 19.9549 5.13308 19.9082 5.22224C19.87 5.29017 19.8275 5.36235 19.7766 5.42179C19.5006 5.74446 19.1482 5.95675 18.7491 6.09685C18.7194 6.10959 18.6897 6.11384 18.6642 6.11808Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="trainer" viewBox="0 0 19 16">
      <g>
        <path
          d="M9.14976 6.71579C10.1529 6.71579 10.9661 5.90266 10.9661 4.89961C10.9661 3.89657 10.1529 3.08344 9.14976 3.08344C8.1466 3.08344 7.33337 3.89657 7.33337 4.89961C7.33337 5.90266 8.1466 6.71579 9.14976 6.71579Z"
          fill="currentColor"
        />
        <path
          d="M9.14963 8.8663C6.78426 8.8663 4.86621 10.7841 4.86621 13.1492V15.6228C4.86621 15.8306 5.03565 16 5.2435 16H13.0603C13.2681 16 13.4376 15.8306 13.4376 15.6228V13.1492C13.433 10.7841 11.515 8.8663 9.14963 8.8663Z"
          fill="currentColor"
        />
        <path
          d="M17.3212 0H0.978227C0.438282 0 0 0.438232 0 0.978117V11.8391C0 12.3789 0.438282 12.8172 0.978227 12.8172H4.06879C4.26534 10.1833 6.46579 8.1073 9.1497 8.1073C11.8336 8.1073 14.0341 10.1833 14.2306 12.8172H17.3212C17.8611 12.8172 18.2994 12.3789 18.2994 11.8391V0.978117C18.2994 0.438232 17.8611 0 17.3212 0ZM9.1497 7.44769C7.74223 7.44769 6.60134 6.30693 6.60134 4.89962C6.60134 3.49231 7.74223 2.35155 9.1497 2.35155C10.5572 2.35155 11.6981 3.49231 11.6981 4.89962C11.6981 6.30693 10.5572 7.44769 9.1497 7.44769Z"
          fill="currentColor"
        />
      </g>
    </symbol>
    <symbol id="course" viewBox="0 0 14 16">
      <path
        d="M2.03698 12.7725H12.9351C12.9684 12.7725 13.0018 12.7683 13.031 12.76C13.0331 12.76 13.0352 12.76 13.0372 12.76C13.2708 12.76 13.4584 12.5702 13.4584 12.3388V0.421162C13.4584 0.187647 13.2687 0 13.0372 0H12.9351H2.71251H2.03698C0.915271 0 0.00205797 0.913213 0.00205797 2.03492C0.00205797 2.04952 0.00414293 2.06411 0.00622789 2.07871C0.00414293 3.11493 -0.00836684 12.639 0.00414293 13.89C0.00414293 13.915 -2.69949e-05 13.938 -2.69949e-05 13.963C-2.69949e-05 15.0409 0.844383 15.9249 1.90771 15.9917C1.92856 15.9958 1.94941 15.9979 1.97235 15.9979H13.0372C13.2708 15.9979 13.4584 15.8082 13.4584 15.5768V13.9901C13.4584 13.7566 13.2687 13.5689 13.0372 13.5689C12.8037 13.5689 12.6161 13.7587 12.6161 13.9901V15.1556H2.03698C1.3969 15.1556 0.873572 14.6469 0.846467 14.013C0.846467 14.0026 0.848552 13.9901 0.848552 13.9797C0.848552 13.9609 0.846467 13.9317 0.846467 13.8942C0.886082 13.2687 1.40315 12.7725 2.03698 12.7725ZM2.32471 2.03492C2.32471 1.80141 2.51444 1.61376 2.74587 1.61376C2.97938 1.61376 3.16703 1.80349 3.16703 2.03492V10.5999C3.16703 10.8335 2.9773 11.0211 2.74587 11.0211C2.51235 11.0211 2.32471 10.8314 2.32471 10.5999V2.03492Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="faq" viewBox="0 0 21 16">
      <path
        d="M18.6274 12.3966C20.1371 11.0315 20.9231 9.38355 20.9231 7.6249C20.9231 4.29208 17.9911 1.46349 13.9113 0.41814C13.3124 0.258263 12.6761 0.147579 12.0273 0.0737894C11.5158 0.0245965 10.9918 0 10.4678 0C4.89079 0 0.324389 3.19754 0.0124765 7.20676C0 7.35434 0 7.48962 0 7.6372C0 8.12913 0.0623825 8.59646 0.187147 9.0638C1.11041 12.5934 5.36489 15.2744 10.4678 15.2744C11.7653 15.2744 13.0379 15.1022 14.2482 14.7579C14.6224 14.6472 15.2962 14.4627 19.0017 16C17.8788 13.4174 18.1658 12.8271 18.6274 12.3966ZM11.7404 12.8025C11.7404 12.9623 11.6281 13.0853 11.4784 13.0853H9.27003C9.13279 13.0853 9.0205 12.9623 9.0205 12.8025V10.6749C9.0205 10.515 9.13279 10.392 9.27003 10.392H11.4908C11.6281 10.392 11.7529 10.515 11.7529 10.6749V12.8025H11.7404ZM13.9737 6.2598C13.7616 6.65334 13.3374 7.13297 12.6886 7.71099C12.2394 8.11683 11.9525 8.43659 11.8277 8.68255C11.7404 8.86702 11.678 9.10069 11.653 9.37125C11.6406 9.51883 11.5283 9.64181 11.4035 9.64181H9.35737C9.22013 9.64181 9.09536 9.51883 9.09536 9.35895C9.09536 8.86703 9.14527 8.47348 9.24508 8.17832C9.34489 7.87087 9.49461 7.60031 9.68176 7.34204C9.88138 7.09608 10.3181 6.65334 10.9918 6.01384C11.3536 5.68178 11.5408 5.37433 11.5408 5.10377C11.5408 4.8332 11.4659 4.61184 11.3162 4.46426C11.1665 4.31668 10.9544 4.23059 10.6549 4.23059C10.343 4.23059 10.0685 4.35357 9.86891 4.58724C9.69423 4.78401 9.56947 5.10377 9.50709 5.5465C9.48213 5.69408 9.35737 5.79247 9.22013 5.78017L7.1116 5.48501C6.97436 5.46041 6.86207 5.31284 6.88702 5.15296C7.01179 4.21829 7.34865 3.4558 7.89762 2.85319C8.50897 2.20138 9.43223 1.86933 10.6924 1.86933C11.6655 1.86933 12.464 2.103 13.0629 2.55803C13.8739 3.17294 14.2856 3.99692 14.2856 5.01768C14.2981 5.46041 14.1858 5.86626 13.9737 6.2598Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="setting" viewBox="0 0 16 16">
      <path
        d="M15.3981 6.45641L13.7324 6.23653C13.6213 5.87229 13.4747 5.5236 13.2993 5.19045L14.3209 3.85786C14.5319 3.58245 14.5075 3.19156 14.261 2.94725L13.0505 1.73681C12.804 1.49028 12.4153 1.46585 12.1399 1.67685L10.8073 2.6985C10.4764 2.52304 10.1277 2.37646 9.76124 2.26541L9.54137 0.599667C9.49695 0.257635 9.20378 0 8.8573 0H7.14492C6.79845 0 6.50305 0.257635 6.45863 0.601888L6.23876 2.26763C5.87451 2.37868 5.52582 2.52526 5.19267 2.70072L3.86008 1.67907C3.58468 1.46807 3.19378 1.4925 2.94947 1.73903L1.73903 2.94947C1.4925 3.196 1.46807 3.58468 1.67907 3.86008L2.70072 5.19267C2.52526 5.5236 2.37868 5.87229 2.26763 6.23876L0.601888 6.45863C0.257635 6.50305 0 6.79622 0 7.1427V8.85508C0 9.20155 0.257635 9.49695 0.601888 9.54137L2.26763 9.76124C2.37868 10.1255 2.52526 10.4742 2.70072 10.8073L1.67907 12.1399C1.46807 12.4153 1.4925 12.8062 1.73903 13.0505L2.94947 14.261C3.196 14.5075 3.58468 14.5319 3.86008 14.3209L5.19267 13.2993C5.5236 13.4747 5.87229 13.6213 6.23876 13.7324L6.45863 15.3981C6.50305 15.7424 6.79845 16 7.14492 16H8.8573C9.20378 16 9.49917 15.7424 9.54359 15.3981L9.76346 13.7324C10.1277 13.6213 10.4764 13.4747 10.8096 13.2993L12.1421 14.3209C12.4175 14.5319 12.8084 14.5075 13.0527 14.261L14.2632 13.0505C14.5097 12.804 14.5341 12.4153 14.3232 12.1399L13.3015 10.8073C13.477 10.4764 13.6235 10.1277 13.7346 9.76124L15.4003 9.54137C15.7446 9.49695 16.0022 9.20155 16.0022 8.85508V7.1427C16 6.79622 15.7424 6.50305 15.3981 6.45641ZM8 10.1011C6.83842 10.1011 5.89894 9.15936 5.89894 8C5.89894 6.83842 6.84064 5.89894 8 5.89894C9.15936 5.89894 10.1011 6.84064 10.1011 8C10.1011 9.16158 9.16158 10.1011 8 10.1011Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="star" viewBox="0 0 11 10">
      <path
        d="M5.05169 0.991659C5.23511 0.620075 5.76498 0.620075 5.94839 0.991659L7.05757 3.23872C7.13034 3.38615 7.27093 3.48838 7.43361 3.51215L9.91481 3.87482C10.3248 3.93474 10.4882 4.43866 10.1914 4.72774L8.39677 6.47568C8.27883 6.59055 8.225 6.75612 8.25283 6.91838L8.67626 9.38716C8.74631 9.79564 8.31754 10.1071 7.95073 9.91422L5.73277 8.74781C5.58708 8.6712 5.41301 8.6712 5.26732 8.74781L3.04936 9.91422C2.68254 10.1071 2.25377 9.79564 2.32383 9.38716L2.74725 6.91838C2.77508 6.75612 2.72125 6.59055 2.60331 6.47568L0.808713 4.72774C0.511916 4.43866 0.675308 3.93474 1.08527 3.87482L3.56647 3.51215C3.72915 3.48838 3.86974 3.38615 3.94251 3.23872L5.05169 0.991659Z"
        fill="#FFC960"
      />
    </symbol>
    <symbol id="cog" viewBox="0 0 16 16">
      <path
        d="M15.3981 6.45641L13.7324 6.23653C13.6213 5.87229 13.4747 5.5236 13.2993 5.19045L14.3209 3.85786C14.5319 3.58245 14.5075 3.19156 14.261 2.94725L13.0505 1.73681C12.804 1.49028 12.4153 1.46585 12.1399 1.67685L10.8073 2.6985C10.4764 2.52304 10.1277 2.37646 9.76124 2.26541L9.54137 0.599667C9.49695 0.257635 9.20378 0 8.8573 0H7.14492C6.79845 0 6.50305 0.257635 6.45863 0.601888L6.23876 2.26763C5.87451 2.37868 5.52582 2.52526 5.19267 2.70072L3.86008 1.67907C3.58468 1.46807 3.19378 1.4925 2.94947 1.73903L1.73903 2.94947C1.4925 3.196 1.46807 3.58468 1.67907 3.86008L2.70072 5.19267C2.52526 5.5236 2.37868 5.87229 2.26763 6.23876L0.601888 6.45863C0.257635 6.50305 0 6.79622 0 7.1427V8.85508C0 9.20155 0.257635 9.49695 0.601888 9.54137L2.26763 9.76124C2.37868 10.1255 2.52526 10.4742 2.70072 10.8073L1.67907 12.1399C1.46807 12.4153 1.4925 12.8062 1.73903 13.0505L2.94947 14.261C3.196 14.5075 3.58468 14.5319 3.86008 14.3209L5.19267 13.2993C5.5236 13.4747 5.87229 13.6213 6.23876 13.7324L6.45863 15.3981C6.50305 15.7424 6.79845 16 7.14492 16H8.8573C9.20378 16 9.49917 15.7424 9.54359 15.3981L9.76346 13.7324C10.1277 13.6213 10.4764 13.4747 10.8096 13.2993L12.1421 14.3209C12.4175 14.5319 12.8084 14.5075 13.0527 14.261L14.2632 13.0505C14.5097 12.804 14.5341 12.4153 14.3232 12.1399L13.3015 10.8073C13.477 10.4764 13.6235 10.1277 13.7346 9.76124L15.4003 9.54137C15.7446 9.49695 16.0022 9.20155 16.0022 8.85508V7.1427C16 6.79622 15.7424 6.50305 15.3981 6.45641ZM8 10.1011C6.83842 10.1011 5.89894 9.15936 5.89894 8C5.89894 6.83842 6.84064 5.89894 8 5.89894C9.15936 5.89894 10.1011 6.84064 10.1011 8C10.1011 9.16158 9.16158 10.1011 8 10.1011Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="invoice" viewBox="0 0 14 16">
      <path
        d="M5.67843 0H7.19216V1.39608C8.88627 1.6 9.98431 2.55686 10.4784 4.26667L8.4 5.12941C7.99216 3.95294 7.36471 3.36471 6.5098 3.36471C6.08627 3.36471 5.74118 3.49804 5.48235 3.75686C5.22353 4.01569 5.0902 4.33726 5.0902 4.71373C5.0902 5.09804 5.21569 5.38824 5.46667 5.59216C5.71765 5.79608 6.25098 6.04706 7.07451 6.35294C7.97647 6.68235 8.68235 6.98824 9.19216 7.28627C9.70196 7.57647 10.1098 7.98431 10.4157 8.49412C10.7216 9.01177 10.8706 9.60784 10.8706 10.298C10.8706 11.3569 10.5569 12.2353 9.92941 12.9412C9.30196 13.6471 8.39216 14.0627 7.2 14.1882V16H5.67843V14.1961C3.70196 13.9922 2.47059 12.8392 2 10.7373L4.3451 10.1255C4.56471 11.4431 5.27843 12.102 6.50196 12.102C7.07451 12.102 7.4902 11.9608 7.76471 11.6784C8.03137 11.3961 8.16471 11.0588 8.16471 10.651C8.16471 10.2353 8.03137 9.92157 7.76471 9.7098C7.49804 9.49804 6.90196 9.22353 5.97647 8.89412C5.1451 8.60392 4.49412 8.32157 4.02353 8.03922C3.55294 7.75686 3.17647 7.36471 2.87843 6.8549C2.58824 6.3451 2.43922 5.75686 2.43922 5.07451C2.43922 4.18824 2.69804 3.38824 3.22353 2.68235C3.74902 1.97647 4.56471 1.53725 5.68627 1.38039V0H5.67843Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="arrow-down" viewBox="0 0 20 20">
      <path
        fill-rule="evenodd"
        d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
        fill="currentColor"
        clip-rule="evenodd"
      />
    </symbol>
    <symbol id="arrow-up" viewBox="0 0 20 20">
      <path
        fill-rule="evenodd"
        d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
        clip-rule="evenodd"
        fill="currentColor"
      />
    </symbol>
    <symbol id="video" viewBox="0 0 24 24">
      <svg fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
        />
      </svg>
    </symbol>
    <symbol id="type-password" viewBox="0 0 24 24">
      <svg
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
        />
      </svg>
    </symbol>
    <symbol id="type-nopassword" viewBox="0 0 24 24">
      <svg
        class="icon-password"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
        />
      </svg>
    </symbol>
    <symbol id="students" viewBox="0 0 32 28">
      <path
        d="M6.18665 23.5331C6.18665 18.1878 10.5198 13.8547 15.865 13.8547V13.8547C21.2103 13.8547 25.5434 18.1878 25.5434 23.5331V26.7633C25.5434 27.4463 24.9897 28 24.3067 28H7.4234C6.74036 28 6.18665 27.4463 6.18665 26.7633V23.5331Z"
        fill="currentColor"
      />
      <circle cx="16" cy="6" r="6" fill="currentColor" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.56143 3.24864C9.07014 3.08728 8.54527 3 8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13C9.42092 13 10.7034 12.4073 11.6137 11.4556C10.0199 10.1725 9 8.20537 9 6C9 5.0335 9.19588 4.11275 9.55009 3.27528C9.55386 3.26639 9.55763 3.25751 9.56143 3.24864Z"
        fill="currentColor"
      />
      <path
        d="M6 24C6 18.4772 10.4772 14 16 14V14C21.5228 14 26 18.4772 26 24V26.776C26 27.452 25.452 28 24.776 28H7.22404C6.54802 28 6 27.452 6 26.776V24Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5807 14.4254C7.24833 16.3156 5 19.8952 5 24V25H0.961749C0.43059 25 0 24.5694 0 24.0383V22C0 17.5817 3.58172 14 8 14C8.90298 14 9.77102 14.1496 10.5807 14.4254Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.5752 8.40683C22.85 7.65635 23 6.84569 23 6C23 5.02324 22.7999 4.09321 22.4386 3.24864C22.9298 3.08728 23.4547 3 24 3C26.7614 3 29 5.23858 29 8C29 10.7614 26.7614 13 24 13C22.5791 13 21.2966 12.4073 20.3863 11.4556C20.4085 11.4377 20.4306 11.4197 20.4527 11.4015C21.3519 10.6594 22.0627 9.69698 22.5025 8.59692C22.5276 8.534 22.5519 8.47064 22.5752 8.40683Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27 25H31.0383C31.5694 25 32 24.5694 32 24.0382V22C32 17.5817 28.4183 14 24 14C23.097 14 22.229 14.1496 21.4193 14.4254C24.7517 16.3156 27 19.8952 27 24V25Z"
        fill="currentColor"
      />
    </symbol>
  </svg>
</template>

<script>
export default {
  name: 'SvgSymbols'
}
</script>