const types = {
  SET_SELECTED_TRAINER: "SET_SELECTED_TRAINER"
};

const trainer = {
  namespaced: true,
  state: () => ({
    selected_trainer: {
      name: "",
      email: ""
    }
  }),
  mutations: {
    [types.SET_SELECTED_TRAINER](state, payload) {
      state.selected_trainer.name = payload.name;
      state.selected_trainer.email = payload.email;
    }
  },
  actions: {
    setSelectedTrainer({ commit }, payload) {
      commit(types.SET_SELECTED_TRAINER, payload);
    }
  },
  getters: {
    getInfoSelectedTrainer: state => {
      return state.selected_trainer;
    }
  }
};
export default trainer;
