import { api } from "./axios";

const getAdminById = id => {
  return api.get(`/v1/admin/${id}`);
};

const updateAdmin = data => {
  return api.patch(`/v1/admin/`, data);
};

const getTop5AndAllMember = () => {
  return api.get(`v1/dashboard/top5`);
};

const getRecentlyJoin = () => {
  return api.get(`v1/dashboard/recently`);
};

const getnewStudent = value => {
  return api.get(`v1/dashboard/newStudent?range=${value}`);
};

export {
  getAdminById,
  updateAdmin,
  getTop5AndAllMember,
  getnewStudent,
  getRecentlyJoin
};
