<template>
  <div class="main-content bg-gradient-info min-screen">
    <!-- Header -->
    <div class="header bg-default py-7 pb-lg-9 pt-lg-5">
      <!-- <div class="container"> -->
      <div class="header-body text-center">
        <div class="row justify-content-center">
          <div class="col-lg-3 col-md-5">
            <img src="/img/Logo-M-eDU.svg" class="img-logo" alt="Logo M-eDU" />
          </div>
        </div>
        <!-- <div class="row justify-content-center my-5"> -->
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-6">
            <h1>Welcome to Dashboard M-eDU PAIN</h1>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <polygon id="s1" points="2560 0 2560 100 0 100" />
            <linearGradient id="g1" x1="0%" x2="100%">
              <stop stop-color="#11cdef" offset="0%" />
              <stop stop-color="#1171ef" offset="100%" />
            </linearGradient>
          </defs>
          <use x="10" y="10" xlink:href="#s1" fill="url(#g1)" />
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-4">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
    <footer class="py-4">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-12">
            <div class="copyright text-center">
              &copy; {{ year }} &mdash;
              <strong>Dashboard M-eDU PAIN</strong>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
// import Vue from 'vue';
// import VueCookies from 'vue-cookies-reactive';
import { SlideYUpTransition } from 'vue2-transitions';

// Vue.use(VueCookies);

export default {
  // name: 'auth-layout',
  components: {
    SlideYUpTransition,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
  // beforeMount() {
  // this.f_checkLogin();
  // },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
#header-shape-gradient {
  --color-stop: #f12c06;
  --color-bot: #faed34;
}
.min-screen {
  min-height: 100vh;
  overflow-x: hidden;
}
.bg-default {
  background: $tabs-course !important;
}
.color-default {
  // fill: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important;
  // fill: red;
  fill: url(#header-shape-gradient) #fff;
}
.img-logo {
  width: 200px;
  height: 100px;
}
</style>
