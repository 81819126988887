<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'List Student Profile',
            src: '#student',
            path: '/dashboard/admin/list-students',
          }"
        />
        <sidebar-item
          :link="{
            name: 'List Trainer',
            src: '#trainer',
            path: '/dashboard/admin/list-trainers',
          }"
        />
        <sidebar-item
          :link="{
            name: 'List Courses',
            src: '#course',
            path: '/dashboard/admin/list-courses',
          }"
        />
        <sidebar-item
          :link="{
            name: 'List Practice Hololens',
            src: '#hololens',
            path: '/dashboard/admin/list-practice',
          }"
        />
        <sidebar-item
          :link="{
            name: 'List Invoice Withdrawal',
            src: '#invoice',
            path: '/dashboard/admin/list-invoice',
          }"
        />
        <sidebar-item
          :link="{
            name: 'List FAQ',
            src: '#faq',
            path: '/dashboard/admin/faq',
          }"
        />
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: 'Setting',
            src: '#cog',
            path: '/dashboard/admin/setting',
          }"
        />
      </template>

      <template slot="links-after">
        <!-- <hr class="my-3" /> -->
        <!-- <h6 class="navbar-heading p-0 text-muted">Documentation</h6> -->

        <!-- <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation"
              target="_blank"
            >
              <i class="ni ni-spaceship"></i>
              <span class="nav-link-text">Getting started</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/foundation/colors.html"
              target="_blank"
            >
              <i class="ni ni-palette"></i>
              <span class="nav-link-text">Foundation</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/avatars.html"
              target="_blank"
            >
              <i class="ni ni-ui-04"></i>
              <span class="nav-link-text">Components</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/plugin_datepicker.html"
              target="_blank"
            >
              <i class="ni ni-chart-pie-35"></i>
              <span class="nav-link-text">Plugins</span>
            </a>
          </li>
        </ul>-->
      </template>
    </side-bar>
    <div class="main-content">
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <router-view></router-view>
      </fade-transition>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import ContentFooter from './ContentFooter.vue';
import { FadeTransition } from 'vue2-transitions';
import { mapGetters, mapActions } from 'vuex'
import { getAdminById } from '@/services/admin'


export default {
  components: {
    ContentFooter,
    FadeTransition
  },
  computed: {
    ...mapGetters("auth", ["getInfoAuth"]),
  },
  methods: {
    ...mapActions('auth', ['updateProfil']),
    initScrollbar() {
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('sidenav');
      }
    },
    async getInfoAdmin() {
      try {
        const { data } = await getAdminById(this.getInfoAuth.id)
        this.updateProfil(data)
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    }

  },
  mounted() {
    this.initScrollbar()
    this.getInfoAdmin()
  }
};
</script>
<style lang="scss">
</style>

